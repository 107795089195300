export const meta = [
    {value:'01', title:'The Dream Factory', image:'./data/images/meta/the_dream_factory.jpg', art:'./data/images/visual/the_maladaptive_daydreamer.jpg', artalt:'the_maladaptive_daydreamer.jpg'},
    {value:'02', title:'How to Eat an Elephant', image:'./data/images/meta/how_to_eat_an_elephant.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'}, 
    {value:'03', title:'The Yellow Brick Road of Poetry', image:'./data/images/meta/the_yellow_brick_road_of_poetry.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'04', title:'The Door to Something More', image:'./data/images/meta/the_door_to_something_more.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'05', title:'The Definition of Done', image:'./data/images/meta/the_definition_of_done.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'06', title:'A Sonnet in Soliloquy', image:'./data/images/meta/a_sonnet_in_soliloquy.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'07', title:'It isn’t really about poetry', image:'./data/images/meta/it_isnt_really_about_poetry.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'08', title:'letters on a page', image:'./data/images/meta/letters_on_a_page.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'09', title:'Music without a Melody', image:'./data/images/meta/music_without_a_melody.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'010', title:'feels like a poem', image:'./data/images/meta/feels_like_a_poem.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'011', title:'The Death of Poetry', image:'./data/images/meta/the_death_of_poetry.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'012', title:'The Death of Hope', image:'./data/images/meta/the_death_of_hope.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'013', title:'The Death of Romance', image:'./data/images/meta/the_death_of_romance.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'}
  ];
export const misc = [
    {value:'001', title:'People On A Rock Floating Through Space Just Because', image:'./data/images/miscellaneous/people_on_a_rock_floating_through_space_just_because.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'002', title:'making good a chain reaction of epic proportions', image:'./data/images/miscellaneous/making_good_a_chain_reaction_of_epic_proportions.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'003', title:'The Foot Soldiers from Sparta', image:'./data/images/miscellaneous/the_footsoldiers_from_sparta.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'004', title:'notes from office hours with Yalie', image:'./data/images/miscellaneous/notes_from_office_hours_with_Yalie.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'005', title:'T-shaped People', image:'./data/images/miscellaneous/t_shaped_people.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'006', title:'Thinking of Tomorrow', image:'./data/images/miscellaneous/thinking_of_tomorrow.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'007', title:'Quarantine to Now', image:'./data/images/miscellaneous/quarantine_to_now.jpg', art:'./data/images/visual/the_spread.jpg', artalt:'blank.jpg'},
    {value:'008', title:'2020 B.C. (before COVID-19)', image:'./data/images/miscellaneous/2020_B_C.jpg', art:'./data/images/visual/pandemic.jpg', artalt:'pandemic.jpg'},
    {value:'009', title:'The Rose Garden', image:'./data/images/miscellaneous/the_rose_garden.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0010', title:'A Wicked Cool Woman to Witness', image:'./data/images/miscellaneous/a_wicked_cool_woman_to_witness.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0011', title:'Two-faced Tombstone', image:'./data/images/miscellaneous/twoFaced_tombstone.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0012', title:'A Still Life In Motion', image:'./data/images/miscellaneous/a_still_life_in_motion.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0013', title:'an ode to the sweet things', image:'./data/images/miscellaneous/an_ode_to_the_sweet_things.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0014', title:'Home is a Headspace', image:'./data/images/miscellaneous/home_is_a_headspace.jpg'},
    {value:'0015', title:'A City Like Mine', image:'./data/images/miscellaneous/a_city_like_mine.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'}
  ];
export const purpose = [
    {value:'0001', title:'after X before Y during Z', image:'./data/images/purpose/after_x_before_y_during_z.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0002', title:'0 to 1', image:'./data/images/purpose/0_to_1.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0003', title:'The Hitchhiker', image:'./data/images/purpose/the_hitchhiker.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0004', title:'Off The Beaten Path', image:'./data/images/purpose/off_the_beaten_path.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0005', title:'Oh, The Places We Could Go…', image:'./data/images/purpose/oh_the_places_we_could_go.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0006', title:'The Trick to Happiness', image:'./data/images/purpose/the_trick_to_happiness.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0007', title:'Lost Time', image:'./data/images/purpose/lost_time.jpg', art:'./data/images/visual/hourglass.jpg', artalt:'hourglass.jpg'},
    {value:'0008', title:'The Oracle at Delphi', image:'./data/images/purpose/the_oracle_at_delphi.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0009', title:'Saut Dans Le Vide', image:'./data/images/purpose/saut_dans_le_vide.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00010', title:'Odds', image:'./data/images/purpose/odds.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00011', title:'Back to Basics', image:'./data/images/purpose/back_to_basics.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00012', title:'Train to Elsewhere', image:'./data/images/purpose/train_to_elsewhere.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00013', title:'The Lily Pond', image:'./data/images/purpose/the_lily_pond.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'}
  ];
export const twoCents = [
    {value:'00001', title:'Ready, Fire, Aim', image:'./data/images/twoCents/ready_fire_aim.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00002', title:'net joy', image:'./data/images/twoCents/net_joy.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00003', title:'The Butterfly Effect', image:'./data/images/twoCents/the_butterfly_effect.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00004', title:'Beauty in the Brokenness', image:'./data/images/twoCents/beauty_in_the_brokenness.jpg', art:'./data/images/visual/diamond_in_the_rough.jpg', artalt:'blank.jpg'},
    {value:'00005', title:'There\'s Still A Light On In The House', image:'./data/images/twoCents/theres_still_a_light_on_in_the_house.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00006', title:'Best by Today', image:'./data/images/twoCents/best_by_today.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00007', title:'The Sun Behind The Clouds', image:'./data/images/twoCents/the_sun_behind_the_clouds.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00008', title:'The Paradox of Choice', image:'./data/images/twoCents/the_paradox_of_choice.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00009', title:'A Word to the Wise', image:'./data/images/twoCents/a_word_to_the_wise.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'000010', title:'The Third Door', image:'./data/images/twoCents/the_third_door.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'000011', title:'The Best Advice', image:'./data/images/twoCents/the_best_advice.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'000012', title:'Option B', image:'./data/images/twoCents/option_b.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'000013', title:'The Power of Habit', image:'./data/images/twoCents/the_power_of_habit.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'000014', title:'A Second Before Infinity', image:'./data/images/twoCents/a_second_before_infinity.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'000015', title:'The Toolbox', image:'./data/images/twoCents/the_toolbox.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'}
];
export const infinity = [
    {value:'000001', title:'Chasing Day', image:'./data/images/infinity/chasing_day.jpg', art:'./data/images/visual/strive.jpg', artalt:'blank.jpg'}, 
    {value:'000002', title:'An Ode:', image:'./data/images/infinity/an_ode.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'000003', title:'That Spark', image:'./data/images/infinity/that_spark.jpg', art:'./data/images/visual/spark.jpg', artalt:'spark.jpg'},
    {value:'000004', title:'About 5,778K', image:'./data/images/infinity/about_5778K.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'000005', title:'The Greatest Thrill', image:'./data/images/infinity/the_greatest_thrill.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'000006', title:'Love in the Time of COVID', image:'./data/images/infinity/love_in_the_time_of_covid.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'000007', title:'A Match that Sparks a Flame', image:'./data/images/infinity/a_match_that_sparks_a_flame.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'000008', title:'a feeling that words can\'t describe', image:'./data/images/infinity/a_feeling_that_words_cant_describe.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'000009', title:'to the woman i have yet meet', image:'./data/images/infinity/to_the_woman_i_have_yet_meet.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000010', title:'Juliet', image:'./data/images/infinity/juliet.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000011', title:'The Kick', image:'./data/images/infinity/the_kick.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000012', title:'This song I adore', image:'./data/images/infinity/this_song_i_adore.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000013', title:'Slow Dance', image:'./data/images/infinity/slow_dance.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000014', title:'Sweep Me Off My Feet', image:'./data/images/infinity/sweep_me_off_my_feet.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000015', title:'The Gold Standard', image:'./data/images/infinity/the_gold_standard.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000016', title:'The Long Con', image:'./data/images/infinity/the_long_con.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000017', title:'Girl from Venus', image:'./data/images/infinity/girl_from_venus.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000018', title:'Boy from Mars', image:'./data/images/infinity/boy_from_mars.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000019', title:'Lines in the Sand', image:'./data/images/infinity/lines_in_the_sand.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000020', title:'Wallflower', image:'./data/images/infinity/wallflower.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000021', title:'There\'s No Such Thing As Too Nice', image:'./data/images/infinity/theres_no_such_thing_as_too_nice.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'}, 
    {value:'0000022', title:'Candle in the Wind', image:'./data/images/infinity/candle_in_the_wind.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000023', title:'when rose-colored glasses put romance on a pedestal', image:'./data/images/infinity/when_rose_colored_glasses_put_romance_on_a_pedestal.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000024', title:'white lies', image:'./data/images/infinity/white_lies.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000025', title:'Smiles & Tears', image:'./data/images/infinity/smiles_and_tears.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000026', title:'Castaway', image:'./data/images/infinity/castaway.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000027', title:'What is Love?', image:'./data/images/infinity/what_is_love.jpg', art:'./data/images/visual/the_infinite_loop.jpg', artalt:'what_is_love.jpg'},
    {value:'0000028', title:'Happy Accidents', image:'./data/images/infinity/happy_accidents.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000029', title:'A Long Conversation...', image:'./data/images/infinity/a_long_conversation.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000030', title:'A Brave New World', image:'./data/images/infinity/a_brave_new_world.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000031', title:'The Stock Price Just Went Up', image:'./data/images/infinity/the_stock_price_just_went_up.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000032', title:'The Stock Price Just Went Up Again', image:'./data/images/infinity/the_stock_price_just_went_up_again.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000033', title:'The Stock Price Just Went Up For A Third Time', image:'./data/images/infinity/the_stock_price_just_went_up_for_a_third_time.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
  ];
export const faith = [
    {value:'0000001', title:'Genesis III', image:'./data/images/faith/genesis_III.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000002', title:'Paradise Lost', image:'./data/images/faith/paradise_lost.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000003', title:'The Fallen Angel', image:'./data/images/faith/the_fallen_angel.jpg', art:'./data/images/visual/the_fallen_angel.jpg', artalt:'the_fallen_angel.jpg'},   
    {value:'0000004', title:'Sin Cielo', image:'./data/images/faith/sin_cielo.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000005', title:'Sunset in Heaven', image:'./data/images/faith/sunset_in_heaven.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000006', title:'Fire in the Sky', image:'./data/images/faith/fire_in_the_sky.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000007', title:'God\'s Pawn Shop', image:'./data/images/faith/gods_pawn_shop.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000008', title:'God of the Tiny Things', image:'./data/images/faith/god_of_the_tiny_things.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'0000009', title:'God\'s Elevator Speech', image:'./data/images/faith/gods_elevator_speech.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00000010', title:'The Gift of Bad Luck', image:'./data/images/faith/the_gift_of_bad_luck.jpg', art:'./data/images/visual/lady_luck.jpg', artalt:'lady_luck.jpg'}, 
    {value:'00000011', title:'The Road Less Traveled', image:'./data/images/faith/the_road_less_traveled.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00000012', title:'Can You Hear Me', image:'./data/images/faith/can_you_hear_me.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00000013', title:'Bear with me for a sec it\'s a good question', image:'./data/images/faith/bear_with_me_for_a_sec_its_a_good_question.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00000014', title:'The Million-dollar Question', image:'./data/images/faith/the_million_dollar_question.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00000015', title:'Who Owns The Sky?', image:'./data/images/faith/who_owns_the_sky.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00000016', title:'King of Everything', image:'./data/images/faith/king_of_everything.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00000017', title:'From One Galaxy To Another', image:'./data/images/faith/from_one_galaxy_to_another.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'}
   
];
export const identity = [
    {value:'1', title:'untitled00', image:'./data/images/identity/untitled00.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'2', title:'headspace', image:'./data/images/identity/headspace.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'3', title:'an ode to my friends', image:'./data/images/identity/an_ode_to_my_friends.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'4', title:'A Shot in the Dark', image:'./data/images/identity/a_shot_in_the_dark.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'5', title:'The Art of Getting By', image:'./data/images/identity/the_art_of_getting_by.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'6', title:'26 \'til infinity', image:'./data/images/identity/26_til_infinity.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'7', title:'string theory', image:'./data/images/identity/string_theory.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'8', title:'The Lone Dreamer', image:'./data/images/identity/the_lone_dreamer.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'9', title:'The Secret Garden In The Old Mansion On The Hill', image:'./data/images/identity/the_secret_garden_in_the_old_mansion_on_the_hill.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'10', title:'the best i can really do', image:'./data/images/identity/the_best_i_can_really_do.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'11', title:'I\'m This Close To Quitting My Job', image:'./data/images/identity/im_this_close_to_quitting_my_job.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'12', title:'Sorry is my favorite word', image:'./data/images/identity/sorry_is_my_favorite_word.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'13', title:'flaws \'n all', image:'./data/images/identity/flaws_n_all.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'14', title:'Not Today Satan', image:'./data/images/identity/not_today_satan.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'15', title:'a fatal attraction', image:'./data/images/identity/a_fatal_attraction.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'16', title:'the garden', image:'./data/images/identity/the_garden.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'17', title:'The ABC Elevator Speech', image:'./data/images/identity/the_abc_elevator_speech.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'18', title:'Death of a Star', image:'./data/images/identity/death_of_a_star.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'19', title:'The Threshold', image:'./data/images/identity/the_threshold.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'20', title:'The Man Upstairs in the Red Suit', image:'./data/images/identity/the_man_upstairs_in_the_red_suit.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'21', title:'The Last Cigarette', image:'./data/images/identity/the_last_cigarette.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'22', title:'Days of Sun', image:'./data/images/identity/days_of_sun.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'23', title:'The Found Poem', image:'./data/images/identity/the_found_poem.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'24', title:'ashamed to be american', image:'./data/images/identity/ashamed_to_be_american.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'25', title:'so...do you like um make art n stuff', image:'./data/images/identity/so_do_you_like_um_make_art_n_stuff.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'26', title:'What\'s behind door number one', image:'./data/images/identity/whats_behind_door_number_one.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'27', title:'the best hasn\'t even happened yet', image:'./data/images/identity/the_best_hasnt_even_happened_yet.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'28', title:'Life Through the Looking-glass', image:'./data/images/identity/life_through_the_looking_glass.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'29', title:'the high road that leads to nowhere', image:'./data/images/identity/the_high_road_that_leads_to_nowhere.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'30', title:'Say Cheese', image:'./data/images/identity/say_cheese.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'31', title:'King David of Israel', image:'./data/images/identity/king_david_of_israel.jpg', art:'./data/images/visual/david.jpg', artalt:'david.jpg'},
    {value:'32', title:'Season 6, Episode 7: "Reruns and Regressions"', image:'./data/images/identity/reruns_and_regressions.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'33', title:'The Absolute Value of Self-talk', image:'./data/images/identity/the_absolute_value_of_self_talk.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'34', title:'the nuke that never went off', image:'./data/images/identity/the_nuke_that_never_went_off.jpg', art:'./data/images/visual/bomb.jpg', artalt:'bomb.jpg'},
    {value:'35', title:'when sunshine turns to stone', image:'./data/images/identity/when_sunshine_turns_to_stone.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'36', title:'Purple Days', image:'./data/images/identity/purple_days.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'37', title:'too much to ask for', image:'./data/images/identity/too_much_to_ask_for.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'38', title:'yet is the operative word', image:'./data/images/identity/yet_is_the_operative_word.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'39', title:'late nights spent looking up at the moon', image:'./data/images/identity/late_nights_spent_looking_up_at_the_moon.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'40', title:'Certainty in an Uncertain World', image:'./data/images/identity/certainty_in_an_uncertain_world.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'41', title:'define: success | noun /səkˈses/', image:'./data/images/identity/define_success.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'42', title:'She Told Me The Poem I Wanted To Write Was Impossible', image:'./data/images/identity/she_told_me_the_poem_i_wanted_to_write_was_impossible.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'43', title:'Moody by Design', image:'./data/images/identity/moody_by_design.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'44', title:'An Ode to the Many Parts of Me', image:'./data/images/identity/an_ode_to_the_many_parts_of_me.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'45', title:'A Character Defining Moment', image:'./data/images/identity/a_character_defining_moment.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'46', title:'hate is a strong word', image:'./data/images/identity/hate_is_a_strong_word.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'47', title:'The Rip Tide', image:'./data/images/identity/the_rip_tide.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'48', title:'The Loudest Silence', image:'./data/images/identity/the_loudest_silence.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'49', title:'The Wheel of Fortune', image:'./data/images/identity/the_wheel_of_fortune.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'50', title:'The Knight of Cups', image:'./data/images/identity/the_knight_of_cups.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'51', title:'my heart lies', image:'./data/images/identity/my_heart_lies.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'52', title:'holding space for grace with patience', image:'./data/images/identity/holding_space_for_grace_with_patience.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'53', title:'where the road curves take small steps', image:'./data/images/identity/where_the_road_curves_take_small_steps.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'54', title:'Ctrl + Z', image:'./data/images/identity/ctrl_z.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'55', title:'thinking too much', image:'./data/images/identity/thinking_too_much.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'56', title:'the things that I dream of', image:'./data/images/identity/the_things_that_i_dream_of.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'57', title:'Until The Last Domino Falls', image:'./data/images/identity/until_the_last_domino_falls.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'58', title:'When That Ship Has Sailed', image:'./data/images/identity/when_that_ship_has_sailed.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'59', title:'things that can never be erased', image:'./data/images/identity/things_that_can_never_be_erased.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'60', title:'getting the f*ck out of dodge', image:'./data/images/identity/getting_the_fck_out_of_dodge.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'61', title:'That\'s Life', image:'./data/images/identity/thats_life.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'62', title:'The Good,The Bad, and The Ugly', image:'./data/images/identity/the_good_the_bad_and_the_ugly.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'63', title:'The Poetry Casino', image:'./data/images/identity/the_poetry_casino.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'64', title:'How To Kill A Friendship For The nth Time', image:'./data/images/identity/how_to_kill_a_friendship_for_the_nth_time.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'65', title:'The Breakfast of Champions', image:'./data/images/identity/the_breakfast_of_champions.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'67', title:'A Hymn for the Hopeless', image:'./data/images/identity/a_hymn_for_the_hopeless.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'68', title:'A Poem that I Wrote For Me to Remind Myself of Who I Am', image:'./data/images/identity/a_poem_that_i_wrote_for_me_to_remind_myself_of_who_i_am.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'}
];
export const race = [
    {value:'00000001', title:'Land of the Free', image:'./data/images/race/land_of_the_free.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00000002', title:'A Better Tomorrow', image:'./data/images/race/a_better_tomorrow.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00000003', title:'deep stuff', image:'./data/images/race/deep_stuff.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00000004', title:'this America', image:'./data/images/race/this_america.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00000005', title:'the America we signed up for', image:'./data/images/race/the_america_we_signed_up_for.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00000006', title:'black joy', image:'./data/images/race/black_joy.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00000007', title:'there\'s something in the water', image:'./data/images/race/theres_something_in_the_water.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'00000008', title:'this brain', image:'./data/images/race/this_brain.jpg', art:'./data/images/visual/the_right_bower.jpg', artalt:'blank.jpg'},
    {value:'00000009', title:'The Race Construct', image:'./data/images/race/the_race_construct.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'000000010', title:'Welcome to America', image:'./data/images/race/welcome_to_america.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'},
    {value:'000000011', title:'The Politics of Voice', image:'./data/images/race/the_politics_of_voice.jpg', art:'./data/images/blank.jpg', artalt:'blank.jpg'}
];